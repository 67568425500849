import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useDriversHook } from "../api/useDrivers.hook.ts";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup
  .object({
    driver: yup.string().required(),
    config: yup.object().required(),
  })
  .required()

export const PlatformPage = () => {
  const { data: drivers, isLoading } = useDriversHook();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<yup.InferType<typeof schema>> = (data) => console.log(data);

  console.log(watch("driver")); // watch input value by passing the name of it

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {JSON.stringify(drivers, null, 2)}
          {isLoading && <LoaderSpinner />}
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-3">
            <select {...register("driver")}>
              <option>--- Select platform driver ---</option>
              {drivers?.drivers.map((d) => (
                <option key={d.name}>{d.nice_name}</option>
              ))}
            </select>

            {/*---*/}

            {/*---*/}

            {errors.driver && <span>This field is required</span>}
            <input type="submit" className="btn block" />
          </form>
        </div>
      </div>
    </main>
  );
};
