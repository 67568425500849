import type { FC } from "react";
import { usePlatformsHook } from "../api/usePlatforms.hook.ts";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";

export const DashboardPage: FC = () => {
  const { data: platforms, isLoading } = usePlatformsHook();

  const resolveDetails = (driver: string) => {
    const details = platforms?.drivers.find((d) => d.name === driver);
    if (!details?.name) {
      return undefined;
    }
    return details;
  };

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {/* --- */}
          <div className="flex flex-row justify-end mb-8">
            <Link to="/platform/new" className="btn">
              Add Platform
            </Link>
          </div>

          <div>
            <ul className="divide-y divide-gray-100">
              {isLoading && <LoaderSpinner />}
              {platforms?.platforms?.map((platform) => {
                const resolvedDetails = resolveDetails(platform.driver);
                return (
                  <li key={platform.id} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-3">
                        <span className="w-12 h-12">
                          {resolvedDetails?.image_url && (
                            <img src={resolvedDetails?.image_url} alt={resolvedDetails?.nice_name ?? platform.driver} />
                          )}
                        </span>
                        <span className="text-sm/6 font-semibold text-gray-900">
                          {resolvedDetails?.nice_name ?? platform.driver}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <Link
                        to={`/platform/${platform.id}`}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                      >
                        Edit
                      </Link>
                      <Menu as="div" className="relative flex-none">
                        <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                        </MenuButton>
                        <MenuItems
                          transition
                          className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <MenuItem>
                            <a
                              href="/"
                              className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                              Edit
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href="/"
                              className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                              Delete
                            </a>
                          </MenuItem>
                        </MenuItems>
                      </Menu>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* --- */}
        </div>
      </div>
    </main>
  );
};
