import { withAuthenticationRequired } from "@auth0/auth0-react";
import type { FC, ComponentType } from "react";

interface AuthenticationGuardProps {
  component: ComponentType;
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div className="page-layout">LOADING!</div>,
  });

  return <Component />;
};
