import { Route, Routes } from "react-router-dom";
import { CallbackPage } from "./pages/callback.page.tsx";
import { DashboardPage } from "./pages/dashboard.page.tsx";
import { LayoutView } from "./views/layout.view.tsx";
import { NotFoundPage } from "./pages/notFound.page.tsx";
import { PlatformPage } from "./pages/platform.page.tsx";

function App() {
  return (
    <LayoutView>
      <Routes>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/platform/new" element={<PlatformPage />} />
        <Route path="/stuff" element={<>,,,,,,,,,</>} />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </LayoutView>
  );
}

export default App;
