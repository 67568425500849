import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { DriversResponse } from "../interfaces/driversResponse.interface.ts";

export const useDriversHook = () => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: [Keys.DRIVERS],
    queryFn: async () => {
      const res = await axios.get<DriversResponse>("/api/drivers");
      return res.data;
    },
  });
  return { data, isLoading, isError, error };
};
