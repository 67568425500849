import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { PlatformsResponse } from "../interfaces/platformsResponse.interface.ts";

export const usePlatformsHook = () => {
  const { isLoading, isError, error, data } = useQuery({
    queryKey: [Keys.PLATFORMS],
    queryFn: async () => {
      const res = await axios.get<PlatformsResponse>("/api/platforms");
      return res.data;
    },
  });
  return { data, isLoading, isError, error };
};
